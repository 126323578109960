





















import { Vue, Component, Prop } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store';
import { AssetScenarioStore } from '@/store/assetScenario/assetScenarioStore';
import { IDiagnosticScenario } from '@/view-models/asset/asset-scenario-view-model';
import { pahEventBus, pahEvents } from '@/services/eventBus/portal-asset-home-event-bus';

@Component({
  name: 'asset-scenario',
})
export default class AssetScenario extends Vue {
  @Prop({ required: true })
  public assetkey!: string;
  get diagnosticScenarios(): IDiagnosticScenario[] {
    return store.getters[`${VuexModuleNamespaces.assetScenario}/${AssetScenarioStore.getters.getScenarios.name}`];
  }

  public emitSelectedKey(selectedKey: string) {
    pahEventBus.$emit(pahEvents.scenarioKey, selectedKey);
  }
}
