
















// Libraries
import { TranslateResult } from 'vue-i18n';
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import { asDate, asNumericDateString } from '@/utils/date-utils';
import { toCamelCase } from '@/utils/string';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
// View Models
import { ITower, ITowerHistoricalAlert } from '@/view-models/tower/tower-view-models';
// Components
import Loading from '@/components/common/Loading.vue';
import { getUomByDisplayValue } from '@/utils/tower-utils';
import { EquipmentIndicatorDisplayValuesEnum, TowerEquipmentTypeEnum, TowerInputTypesEnum } from '@/enums/tower-home';
import store, { VuexModuleNamespaces } from '@/store';
import { TowerStore } from '@/store/tower/towerStore';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
// Services
// Stores
@Component({
  name: 'tower-summary-alert-card',
  components: {
    Loading
  }
})
export default class TowerSummaryAlertCard extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public alert!: any;
  public showTooltip: boolean = window.innerWidth > 1440;
  public toolTipClass: boolean = false;
  // VUEX
  // Properties
  // Fields
  // Getters

  public mounted(): void {
    window.addEventListener('resize', this.onResize);
  }
  public get tower(): ITower {
    return store.getters[
      `${VuexModuleNamespaces.tower}/${TowerStore.getters.getCurrentTower.name}`
    ];
  }
  public get indicatorName(): string {
    return this.alert.displayName
        ? this.$t(`tower.displayValues.${toCamelCase(this.alert?.displayName)}`).toString()
        : '';
  }
  public get alertTimestamp(): string {
    return asNumericDateString(asDate(this.alert?.alertTimestamp));
  }
  public get stage() {
    return this.tower.stages.find(stage => stage.stageKey === this.alert?.stageKey);
  }
  public get stageName(): string | TranslateResult {
    if (this.stage) {
      return this.$t('tower.stages.nameTitle', { num: this.stage.order });
    }
    return '';
  }
  public get equipmentName(): string {
    if (this.alert.equipmentType) {
      if (this.alert.equipmentType === TowerEquipmentTypeEnum.None) {
        return this.$t('tower.stages.input').toString();
      }
      return this.alert.equipmentName
          ? this.$t(`tower.equipments.types.${toCamelCase(this.alert.equipmentType)}${(this.alert.equipmentName).split(' ').join('')}`).toString()
          : this.$t(`tower.equipments.types.${toCamelCase(this.alert.equipmentType)}`).toString();
    } else {
      return '';
    }
  }

  public get alertMessage(): string {
    const value = Math.abs(this.alert.thresholdValue - this.alert.value).toFixed(2);
    const direction = this.alert.value < this.alert.thresholdValue ? 'below' : 'above';
    const uom = getUomByDisplayValue(this.alert.displayName, TowerInputTypesEnum.TowerEquipment);
    return `${value}${uom} ${direction} ${this.alert.thresholdValue}${uom} threshold`;
  }
  public get alertStatus(): any {
    return {
      active: (this.alert.status === 'Active'),
      'alert--active': (this.alert?.status === 'Active')
    };
  }
  public onResize() {
    this.showTooltip = window.innerWidth > 1440;
  }
  public beforeDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  @Emit('show-modal')
  public showModal() {
    return this.alert;
  }
}
