import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import {
  IStageWidgetDataRequest,
  IStageWidgetDataResponse,
  ITower, ITowerHistoricalAlert,
  ITowerCustomThresholdRequest,
  ITowerThresholdsResponse,
  IValidationsWidgetDataRequest,
  IValidationsWidgetDataResponse, ITowerAlertHistoryRequest, ITowerWidgetDataRequest, ITowerWidgetDataResponse
} from '@/view-models/tower/tower-view-models';

export default class TowerService extends BaseService {

  constructor(axiosInstance: AxiosInstance, baseApiUrl: string) {
    super(`${baseApiUrl}/api/tower`, axiosInstance);
  }

  // GET /tower/{towerKey}
  public getTower(key: string): Promise<ITower> {
    return this.httpClient.get<ITower>(key);
  }
  // POST /tower/towerWidgetData
  public getTowerWidgetData(request: ITowerWidgetDataRequest): Promise<ITowerWidgetDataResponse> {
    return this.httpClient.post<ITowerWidgetDataResponse>('towerWidgetData', request);
  }
  // POST /tower/stageWidgetData
  public getStageWidgetData(request: IStageWidgetDataRequest): Promise<IStageWidgetDataResponse> {
    return this.httpClient.post<IStageWidgetDataResponse>('stageWidgetData', request);
  }
  // POST /tower/validationWidgetData
  public getValidationWidgetData(request: IValidationsWidgetDataRequest): Promise<IValidationsWidgetDataResponse> {
    return this.httpClient.post<IValidationsWidgetDataResponse>('validationWidgetData', request);
  }
  // GET /tower/{towerKey}/getTowerThresholds
  public getTowerThresholds(key: string): Promise<ITowerThresholdsResponse> {
    return this.httpClient.get<ITowerThresholdsResponse>(`${key}/getTowerThresholds`);
  }
  // POST /tower/storeCustomThreshold
  public storeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<ITowerThresholdsResponse> {
    return this.httpClient.post<ITowerThresholdsResponse>('storeCustomThreshold', request);
  }
  // DELETE /tower/removeCustomThreshold
  public removeCustomThreshold(request: ITowerCustomThresholdRequest): Promise<ITowerThresholdsResponse> {
    return this.httpClient.delete<ITowerThresholdsResponse>('removeCustomThreshold');
  }
  // GET /tower/{towerKey}
  public getAlertHistory(request: ITowerAlertHistoryRequest): Promise<ITowerHistoricalAlert[]> {
    return this.httpClient.get<ITowerHistoricalAlert[]>(`${request.key}/alertHistory?activeOnly=${request.activeOnly}&CurrentPage=${request.currentPage}&SortBy=${request.sortBy}&SortDesc=${request.sortDesc}`);
  }
}
