import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IDiagnosticScenario } from '@/view-models/asset/asset-scenario-view-model';

export class AssetScenarioService extends BaseService {

  constructor(axiosInstance: AxiosInstance, intelligentDiagnosticsApiUrl: string) {
    super(`${intelligentDiagnosticsApiUrl}/api`, axiosInstance);
  }

  public async getScenarios(assetKey: string): Promise<IDiagnosticScenario[]> {
    return this.httpClient.get<IDiagnosticScenario[]>(`diagnostics/asset/${assetKey}/scenarios`);
  }
}
