export function ensureTrailing(val: string, trail: string) {
    return val != null && !val.endsWith(trail) ? `${val}${trail}` : val;
}

export function toCamelCase(str: string) {
    return convertToCase(str, (word, index) => {
        const head = index === 0 ? word.charAt(0).toLowerCase() : word.charAt(0).toUpperCase();
        return `${head}${word.slice(1).toLowerCase()}`;
    });
}
function convertToCase(str: string, converter: ((word: string, index?: number) => string)) {
    return str
      .replace(/(?:[_\-+]+)/g, ' ')
      .trim()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, converter)
      .replace(/\s+/g, '');
}
function hexDec4Chars() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
}
const s4 = hexDec4Chars;

export function newGuid(): string {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}