var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"smr-overview"},[(
      _vm.smartAirData &&
        _vm.smartAirData.smartAirOverview &&
        _vm.smartAirData.smartAirOverview.length > 0
    )?_c('span',[_c('div',{staticClass:"cells-wrapper"},_vm._l((_vm.smartAirData.smartAirOverview),function(smartAirDetail,index){return _c('div',{key:index,staticClass:"cell-detail",attrs:{"id":("sar-cell-detail" + index)},on:{"click":function($event){return _vm.launchSmartAir(smartAirDetail.zoneIdentifier)}}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"cell-name"},[_vm._v(" "+_vm._s(smartAirDetail.zoneName)+" ")]),_c('div',{staticClass:"method-used"},[(smartAirDetail.methodUsed !== 'None')?_c('span',[(_vm.internaluser)?_c('vue-markdown',{staticClass:"method-used-label"},[_vm._v(_vm._s(_vm.smartAirMethodName[smartAirDetail.methodUsed])+" ")]):_vm._e()],1):_c('span',[_vm._v("N/A")])])]),(
              (!smartAirDetail.projected &&
                !smartAirDetail.current &&
                !smartAirDetail.targetO2) ||
                smartAirDetail.projectedBelowTargetFlag ||
                (smartAirDetail.projected &&
                  smartAirDetail.projected.lowValue <
                  smartAirDetail.targetO2) ||
                !smartAirDetail.targetO2
            )?_c('div',{staticClass:"warning-icon",on:{"mouseover":function($event){return _vm.showWarningTooltip(index)},"mouseleave":function($event){return _vm.hideWarningTooltip(index)}}},[_c('em',{staticClass:"mdi mdi-alert-outline warning-ico"}),_c('div',{staticClass:"tool-tip-wrapper",class:{ 'is-active': _vm.showWarning && _vm.hoveredIndex === index }},[_c('em',{staticClass:"mdi mdi-menu-right tooltip-icon"}),_c('div',{staticClass:"tool-tip-inner"},[(
                    !smartAirDetail.projected &&
                      !smartAirDetail.current &&
                      !smartAirDetail.targetO2
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("assetHome.zoneDataUnavailable"))+" ")]):(!smartAirDetail.targetO2)?_c('span',[_c('vue-markdown',[_vm._v(_vm._s(_vm.$t("assetHome.targetO2Unavailable")))])],1):_c('span',[_vm._v(" "+_vm._s(smartAirDetail.projectedBelowTargetFlag ? _vm.$t("assetHome.limitedAirLabel") : _vm.$t("assetHome.targetO2ProjectedError"))+" ")])])])]):_vm._e()]),_c('div',{staticClass:"bottom"},[_c('table',[_c('thead',[_c('tr',[_c('th',{attrs:{"id":"target-value"}},[_c('vue-markdown',[_vm._v(" "+_vm._s(_vm.$t("assetHome.target"))+" ")])],1),_c('th',{attrs:{"id":"current-value"}},[_c('vue-markdown',[_vm._v(" "+_vm._s(_vm.$t("assetHome.current"))+" ")])],1),_c('th',{attrs:{"id":"projected-value"}},[_c('vue-markdown',[_vm._v(" "+_vm._s(_vm.$t("assetHome.projected"))+" ")])],1)])]),_c('tbody',[_c('tr',{class:smartAirDetail.projectedBelowTargetFlag ||
                    (smartAirDetail.projected &&
                      smartAirDetail.projected.lowValue <
                      smartAirDetail.targetO2)
                    ? 'warning'
                    : ''},[_c('td',{staticClass:"target"},[(smartAirDetail.targetO2 !== null)?_c('span',[_vm._v(" "+_vm._s(smartAirDetail.targetO2)+"%"),_c('span',[_vm._v(" ("+_vm._s(smartAirDetail.targetO2Type)+")")])]):_c('span',[_vm._v("--")])]),_c('td',{staticClass:"current"},[(smartAirDetail.current !== null)?_c('span',[_vm._v(" "+_vm._s(smartAirDetail.current.lowValue)+" - "+_vm._s(smartAirDetail.current.highValue)+"% ")]):_c('span',[_vm._v("--")])]),_c('td',{staticClass:"projected"},[(smartAirDetail.projected !== null)?_c('span',[_vm._v(" "+_vm._s(smartAirDetail.projected.lowValue)+" - "+_vm._s(smartAirDetail.projected.highValue)+"% ")]):_c('span',[_vm._v("--")])])])])])])])}),0)]):_vm._e(),(
      _vm.smartAirApiError ||
        (_vm.smartAirData.isSettingsDataAvailable &&
          _vm.smartAirData.smartAirOverview &&
          _vm.smartAirData.smartAirOverview.length === 0)
    )?_c('div',{staticClass:"no-smr"},[_c('em',{staticClass:"mdi mdi-alert-outline"}),_vm._v(" "+_vm._s(_vm.$t("assetHome.overviewError"))+" ")]):_vm._e(),(_vm.smartAirData && _vm.smartAirData.isSettingsDataAvailable === false)?_c('div',{staticClass:"no-smr"},[_vm._v(" "+_vm._s(_vm.$t("assetHome.noSmartAir"))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }