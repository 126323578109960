































































































































import { Vue, Prop, Watch, Component } from 'vue-property-decorator';
import { BootstrapVue, BDropdown } from 'bootstrap-vue';
import HomeNotifications from './components/homeNotifications/HomeNotifications.vue';
import Icon from '@/components/common/Icon.vue';
import Loading from '@/components/common/Loading.vue';
import store, { VuexModuleNamespaces } from '@/store';
import { AppStore } from '@/store/app/appStore';
import { AssetStore } from '@/store/assetStore/assetStore';
import { AssetScenarioStore } from '@/store/assetScenario/assetScenarioStore';
import { BurnerStore } from '@/store/burner/burnerStore';
import { ErrorStore } from '@/store/error/errorStore';
import AssetScenario from '@/components/assetHome/components/AssetScenario.vue';
import SmartAirOverview from '@/components/assetHome/components/SmartAirOverview.vue';
import { AssetSmartAirStore } from '@/store/smartAir/smartAirStore';
import { pahEventBus } from '@/services/eventBus/portal-asset-home-event-bus';
import { UserStore } from '@/store/user/userStore';

Vue.use(BootstrapVue);

@Component({
  name: 'asset-home',
  components: {
    HomeNotifications,
    Icon,
    Loading,
    AssetScenario,
    SmartAirOverview
  },
})
export default class AssetHome extends Vue {
  @Prop({ required: true })
  public assetkey!: string;

  @Prop({ required: true })
  public internaluser!: boolean;

  @Prop({ required: true })
  public timestamp!: string;

  public isSmartAirVisible: boolean = false;
  public zoneKey: string = '';

  private isLoading: boolean = false;
  private isNotFound: boolean = false;
  private sortNewestFirst: boolean = true;
  private isDropdownOpen: boolean = false;
  private isAssetBound: boolean = false;

  get smartAirData(): SmartAirOverview[] {
    return store.getters[
      `${VuexModuleNamespaces.smartAir}/${AssetSmartAirStore.getters.getSmartAirData.name}`
    ];
  }

  get smartAirLoader(): boolean {
    return store.getters[
      `${VuexModuleNamespaces.smartAir}/${AssetSmartAirStore.getters.getSmartAirLoader.name}`
    ];
  }

  get customerAndAssetDetails(): string {
    return JSON.stringify(store.getters[`${VuexModuleNamespaces.user}/${UserStore.getters.customerAssetDetails.name}`]);
  }

  public mounted() {
    pahEventBus.$on('zoneKey', async (key: string) => {
      this.zoneKey = key;
      this.toggleSmartAir();
    });
  }

  @Watch('assetkey', { immediate: true })
  public async onAssetKeyChange(newKey: string, oldKey: string): Promise<void> {
    if (newKey && newKey !== oldKey) {
      await this.bindAsset();
    }
  }
  public openSmartAir() {
    this.zoneKey = '';
    this.toggleSmartAir();
  }

  @Watch('timestamp', { immediate: true })
  public onEmberRun(): void {
    this.getSmartAirData();
  }

  public toggleSmartAir(): void {
    this.isSmartAirVisible = !this.isSmartAirVisible;
  }

  public async bindAsset(): Promise<void> {
    this.isLoading = true;
    // Fetch diagram and burner info
      store.commit(
        `${VuexModuleNamespaces.asset}/${AssetStore.mutations.updateReceivedAssetKey.name}`,
        this.assetkey
      );

      // Find the asset and set selected in the store;
      await store.dispatch(
        `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
        {
          action: async () =>
            store.dispatch(
              `${VuexModuleNamespaces.asset}/${AssetStore.actions.loadAssets.name}`
            ),
          errorMsg: 'Error loading asset.',
        }
      );

      const foundAsset = store.getters[
        `${VuexModuleNamespaces.asset}/${AssetStore.getters.findAsset.name}`
      ](this.assetkey);

      if (!foundAsset) {
        this.isLoading = false;
        this.isNotFound = true;
        return;
      } else {
        // Add asset to store
        store.commit(
          `${VuexModuleNamespaces.asset}/${AssetStore.mutations.selectAsset.name}`,
          foundAsset
        );
        // Load burner readings
        await store.dispatch(
          `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
          {
            action: async () =>
              store.dispatch(
                `${VuexModuleNamespaces.burner}/${BurnerStore.actions.getBurnersFromEMA.name}`
              ),
            errorMsg: 'Error retrieving burner information from EMA.',
          }
        );
      }
    this.isAssetBound = true;
    this.isLoading = false;
  }

  //Load the ember overview data for Smart Air
  public async getSmartAirData(): Promise<void> {
    store.dispatch(
      `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
      {
        action: async () =>
          store.dispatch(
            `${VuexModuleNamespaces.smartAir}/${AssetSmartAirStore.actions.loadSmartAirOverviewData.name}`,
            this.assetkey
          ),
        errorMsg: 'Error loading ember recommendations smart air data.',
      }
    );
  }

  public changeSortNewestFirst(value: boolean) {
    this.sortNewestFirst = value;
    this.isDropdownOpen = false;
    store.dispatch(`${VuexModuleNamespaces.burner}/${BurnerStore.actions.addSortingLog.name}`, value);
  }

  public toggleDropdown() {
    // Ensure that menu actually opens.
    this.isDropdownOpen = !this.isDropdownOpen;
    const sortDropdown = this.$refs.sortDropdown as BDropdown;
    if (this.isDropdownOpen) {
      sortDropdown.show();
    } else {
      sortDropdown.hide();
    }
  }

  public get receivedAssetKey(): string {
    return store.getters[
      `${VuexModuleNamespaces.asset}/${AssetStore.getters.receivedAssetKey.name}`
    ];
  }
}
