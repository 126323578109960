import { render, staticRenderFns } from "./HomeNotifications.vue?vue&type=template&id=eb6e9e74&scoped=true&"
import script from "./HomeNotifications.vue?vue&type=script&lang=ts&"
export * from "./HomeNotifications.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./HomeNotifications.vue?vue&type=style&index=0&id=eb6e9e74&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "eb6e9e74",
  null
  ,true
)

export default component.exports