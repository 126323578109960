 import { IStatusBurner } from '@/view-models/burner/burner-view-models';
 import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
 import store, { IRootState, VuexModuleNamespaces } from '..';
 import { AssetBurnerService } from '@/services/asset/asset-burner-service';
 import sharedAxiosInstance from '@/services/common/api-service';
 import ConfigFactory from '@/services/config/config';
 import { AssetStore } from '@/store/assetStore/assetStore';
import LoggerService from '@/services/logger/logger-service';
import { LogFeature } from '@/view-models/common/log-model';
 export interface IBurnerStoreState {
  burners: IStatusBurner[];
  loading: boolean;
  emaBurnersList: IStatusBurner[];
 }

 export interface IBurnerStoreGetters extends GetterTree<IBurnerStoreState, IRootState> {
  isLoading(state: IBurnerStoreState): boolean;
  getEMABurnersList(state: IBurnerStoreState): IStatusBurner[];
}

 export interface IBurnerStoreMutations extends MutationTree<IBurnerStoreState> {
  updateLoading(state: IBurnerStoreState, isLoading: boolean): void;
  setEMABurners(state: IBurnerStoreState, burnersList: IStatusBurner[]): void;
}

 export interface IBurnerStoreActions extends ActionTree<IBurnerStoreState, IRootState> {
  getBurnersFromEMA(context: IBurnerContext): Promise<void>;
  addSortingLog(context: IBurnerContext, sortNewestFirst: boolean): Promise<void>;
}

 export type IBurnerContext = ActionContext<IBurnerStoreState, IRootState>;

 export const BurnerStore = {
  namespaced: true as true,
  state: {
    burners: [],
    loading: false,
    emaBurnersList: []
  } as IBurnerStoreState,
  getters: {
    isLoading(state: IBurnerStoreState): boolean {
      return state.loading;
    },
    getEMABurnersList(state: IBurnerStoreState): IStatusBurner[] {
      return state.emaBurnersList;
    }
  } as IBurnerStoreGetters,
  mutations: {
    updateLoading(state: IBurnerStoreState, isLoading: boolean): void {
      state.loading = isLoading;
    },
    setEMABurners(state: IBurnerStoreState, burnersList: IStatusBurner[]): void {
      state.emaBurnersList = burnersList;
    }
  } as IBurnerStoreMutations,
  actions: {
    async getBurnersFromEMA(context: IBurnerContext): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const assetBurnerService = new AssetBurnerService(sharedAxiosInstance,
      process.env.VUE_APP_EMA_API_BASE_URL ?
      process.env.VUE_APP_EMA_API_BASE_URL :
      conf.get('emaApiUrl'));
      const assetKey = store.getters[`${VuexModuleNamespaces.asset}/${AssetStore.getters.receivedAssetKey.name}`];
      try {
        const response = await assetBurnerService.getBurnersByAssetKey(assetKey);
        context.commit('setEMABurners', response);
      } catch (error) {
        throw error;
      }
    },
    async addSortingLog(context: IBurnerContext, sortNewestFirst: boolean): Promise<void> {
      const conf = await ConfigFactory.GetConfig();
      const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
      const additionalData = {
        assetKey: store.state.asset.selectedAsset?.key ?? ''
      };
      const message = LogFeature['AH-BurnerFilterAssetSummary'] + (sortNewestFirst ? 'Newly Added' : 'Oldest Added') + `for ${store.state.asset.selectedAsset?.name }`;
      await loggerService.addLogs(message, 'AH-BurnerFilterAssetSummary', additionalData);
    }
  } as IBurnerStoreActions
};
