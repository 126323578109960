
















































// Libraries
import { Vue, Component, Prop, Emit, Ref } from 'vue-property-decorator';
import { Options } from 'highcharts';
// View Models
import { IWidgetDataTypes } from '@/view-models/tower/tower-view-models';
import { TowerInputTypesEnum, TowerUnitOfMeasurementsEnum } from '@/enums/tower-home';
import { newGuid, toCamelCase } from '@/utils/string';
// Components
import HighchartsVue, { Chart } from 'highcharts-vue';
// Services
import TowerWidgetBuilder from '@/utils/tower-widget-builder';
import { getValueYWithFixedDecimalPoint } from '@/utils/tower-utils';
import store, { VuexModuleNamespaces } from '@/store';
import { TowerStore } from '@/store/tower/towerStore';
Vue.use(HighchartsVue);
// Store
@Component({
  name: 'chart-widget',
  components: {
    Chart
  }
})
export default class ChartWidget extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public widgetData!: IWidgetDataTypes;
  @Prop({ required: false, default: false })
  public readonly showStageNumber!: boolean;
  // VUEX
  // Properties
  public chartPointData = {} as any;
  public showResetZoomIcon: boolean = false;
  // Refs
  @Ref()
  private readonly widgetChart!: any;
  // Fields
  // Getters
  public get chartOptions(): Options {
    return new TowerWidgetBuilder(this.widgetData, this.timeSelections, this).buildWidget();
  }
  public get chartPointDataY(): string {
    return this.chartPointData && this.chartPointData.y ? getValueYWithFixedDecimalPoint(this.chartPointData.y) : '';
  }
  public get uom(): string {
    if (this.widgetData.type === TowerInputTypesEnum.Validations) {
      return (this.widgetData.uom === TowerUnitOfMeasurementsEnum.none) ? TowerUnitOfMeasurementsEnum.empty : this.$t(`assets.tower.uoms.${toCamelCase(this.widgetData.uom)}`).toString();
    } else {
      return (this.widgetData.uom === TowerUnitOfMeasurementsEnum.none) ? TowerUnitOfMeasurementsEnum.empty : this.widgetData.uom;
    }
  }
  public get timeSelections() {
    return store.getters[`${VuexModuleNamespaces.tower}/${TowerStore.getters.getCurrentTimeSelections.name}`];
  }
  public get dataKey(): string {
    switch (this.widgetData.type) {
      case (TowerInputTypesEnum.TowerEquipment):
        return `Chart:${this.widgetData.equipmentKey}`;
      case (TowerInputTypesEnum.Validations):
        return `Chart:${this.widgetData.validationKey}`;
      default:
        return newGuid();
    }
  }
  public get dataTestHook(): string {
    switch (this.widgetData.type) {
      case (TowerInputTypesEnum.TowerEquipment):
        return this.widgetData.displayValue;
      case (TowerInputTypesEnum.Validations):
        return this.widgetData.displayValue;
      default:
        return newGuid();
    }
  }
  public get dataType(): string {
    return this.widgetData && this.widgetData.chartSettings ? this.widgetData.chartSettings.widgetType : '';
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    window.addEventListener('resize', this.triggerRender);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    window.removeEventListener('resize', this.triggerRender);
  }
  // destroyed(): void {}
  // Private Methods
  private triggerRender() {
    this.updateChart({});
  }
  // Helper Methods
  // Event Methods
  public updateChart(chartOptions: Options): void {
    // Pass chartOptions that only contain props you want to change, redraw set to true
    this.widgetChart && this.widgetChart.chart.update(chartOptions, true);
  }
  public buildChartLegend(data: any, time: string, thresholds: any): void {
  if (data) {
      this.chartPointData = data;
      this.chartPointData.time = time;
    }
    if (thresholds.length > 0) {
      this.chartPointData.thresholds = thresholds[0];
    }
  }
  public resetZoom(): void {
    this.widgetChart.chart.zoomOut();
    this.showResetZoomIcon = false;
  }

  // Emitters
  @Emit('toggle-zoom-icon')
  public toggleZoomResetIcon(): void {
    this.showResetZoomIcon = true;
  }
  // Watchers
}
