import { IAsset } from '@/view-models/asset/assets-view-models';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '../../store';
import sharedAxiosInstance from '@/services/common/api-service';
import { AssetService } from '@/services/asset/asset-service';
import ConfigFactory from '@/services/config/config';
import LoggerService from '@/services/logger/logger-service';
import { LogFeature } from '@/view-models/common/log-model';

export interface IAssetStoreState {
  assets: IAsset[];
  receivedAssetKey: string; // This is the string received from the parent app (if present)
  selectedAsset: undefined | IAsset;
}

export interface IAssetStoreGetters extends GetterTree<IAssetStoreState, IRootState> {
  findAsset(state: IAssetStoreState): (assetKey: string) => IAsset | undefined;
  receivedAssetKey(state: IAssetStoreState): string;
  selectedAsset(state: IAssetStoreState): null | IAsset;
}

export interface IAssetStoreMutations extends MutationTree<IAssetStoreState> {
  selectAsset(state: IAssetStoreState, selected: IAsset): void;
  updateReceivedAssetKey(state: IAssetStoreState, newReceivedAssetKey: string): void;
}

export interface IAssetStoreActions extends ActionTree<IAssetStoreState, IRootState> {
  loadAssets(context: IAssetContext): void;
}

export type IAssetContext = ActionContext<IAssetStoreState, IRootState>;

export const AssetStore = {
    namespaced: true as true,
    state: {
      assets: new Array<IAsset>(),
      receivedAssetKey: '',
      selectedAsset: {},
    } as IAssetStoreState,
    getters:  {
      findAsset: (state: IAssetStoreState) =>
        (assetKey: string): IAsset | undefined => {
          return state.assets.find((a: IAsset) => a.key === assetKey);
        },
      receivedAssetKey(state: IAssetStoreState): string {
        return state.receivedAssetKey;
      },
      selectedAsset(state: IAssetStoreState): IAsset | undefined {
        return state.selectedAsset;
      },
    } as IAssetStoreGetters,
    mutations: {
      selectAsset(state: IAssetStoreState, selected: IAsset): void {
          state.selectedAsset = selected;
      },
      updateReceivedAssetKey(state: IAssetStoreState, newReceivedAssetKey: string): void {
        state.receivedAssetKey = newReceivedAssetKey;
      }
    } as IAssetStoreMutations,
    actions: {
      async loadAssets(context: IAssetContext): Promise<void> {
        const conf = await ConfigFactory.GetConfig();
        const assetService = new AssetService(sharedAxiosInstance,
        process.env.VUE_APP_KES_PORTAL_API_BASE_URL ?
        process.env.VUE_APP_KES_PORTAL_API_BASE_URL :
        conf.get('baseApiUrl'));
        await assetService.getCustomerAssets().then(async (resp) => {
          const conf = await ConfigFactory.GetConfig();
          const loggerService: LoggerService = new LoggerService(conf.get('adbApiUrl'), sharedAxiosInstance);
          const additionalData = {
            assetKey: context.state.selectedAsset?.key ?? ''
          };
          const message = LogFeature['AH-AssetSelectedAssignmentTree'] + context.state.selectedAsset?.name;
          await loggerService.addLogs(message, 'AH-AssetSelectedAssignmentTree', additionalData);
          context.state.assets = resp;
        });
      }
    } as IAssetStoreActions
};
