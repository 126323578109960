export default class WidgetSeriesColors {
  private static readonly WIDGET_SERIES_COLORS: string[] = [
    // From /d/#/console/16088141/379423106/preview
    '#8CA6C3',
    '#467AB8',
    '#d55e7b',
    '#a400ff',
    '#0074FF',
    '#29A386',
    '#9576BC',
    '#7B5DD5',
    '#5D7BD5'
  ];

  public static getColor(index: number): string {
    const n = WidgetSeriesColors.WIDGET_SERIES_COLORS.length;
    return WidgetSeriesColors.WIDGET_SERIES_COLORS[index % n];
  }
};
