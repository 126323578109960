


















// Libraries
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
// View Models
// Components
// Services

@Component({
  name: 'slide-out-panel'
})
export default class SlideOutPanel extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public isPanelOpen: boolean = false;
  @Prop({ required: false, default: null })
  public title?: string;
  @Prop({ required: false, default: null })
  public icon?: string;
  @Emit('toggle-panel')
  private togglePanel() {}
}
