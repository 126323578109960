














































































// Libraries
import { Vue, Component, Prop } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store';
import { TowerStore } from '@/store/tower/towerStore';

// View Models
import { ITower, ITowerAlertHistoryRequest, ITowerHistoricalAlert, ITowerIndicator } from '@/view-models/tower/tower-view-models';
import { ErrorStore } from '@/store/error/errorStore';
import { UserStore } from '@/store/user/userStore';
// Components
import TowerSummaryAlertCard from '@/components/towerHome/TowerSummaryAlertCard.vue';
import SlideOutPanel from '@/components/towerHome/SlideOutPanel.vue';
import TowerModal from '@/components/towerHome/TowerModal.vue';
import ChartWidget from '@/components/towerHome/ChartWidget.vue';
import Loading from '@/components/common/Loading.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { BDropdown } from 'bootstrap-vue';
import { TowerEquipmentTypeEnum } from '@/enums/tower-home';
import { toCamelCase } from '@/utils/string';

@Component({
  name: 'tower-home',
  components: {
    TowerSummaryAlertCard,
    Loading,
    SlideOutPanel,
    InfiniteLoading,
    TowerModal,
    ChartWidget
  }
})
export default class TowerHome extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public assetkey!: string;

  // Properties
  public chartWidgetData: ITowerIndicator = {} as ITowerIndicator;
  public alertHistory: ITowerHistoricalAlert[] = [];
  public isModalOpen: boolean = false;
  public loadingData: boolean = false;
  public isLoadingAlerts: boolean = true;
  public isPanelOpen: boolean = false;
  public showTowerGraphic: boolean = false;
  private currentPage: number = 1;
  private sortByKey: string = 'alertTimestamp';
  private sortDesc: boolean = true;
  private activeOnly: boolean = false;

  // VUEX
  public get tower(): ITower | null {
    return store.getters[
      `${VuexModuleNamespaces.tower}/${TowerStore.getters.getCurrentTower.name}`
    ];
  }
  public get currentAlertHistory(): ITowerHistoricalAlert[] {
    return store.getters[`${VuexModuleNamespaces.tower}/${TowerStore.getters.getCurrentAlertHistory.name}`];
  }
  public get customerAndAssetDetails(): string {
    return JSON.stringify(store.getters[`${VuexModuleNamespaces.user}/${UserStore.getters.customerAssetDetails.name}`]);
  }

  public get activeAlerts() {
    return this.tower?.alerts;
  }

  public get assetKey() {
    return this.assetkey;
  }
  public get towerGraphic() {
    return this.tower?.imageSignedUrl;
  }
  public get panelTitle() {
    return this.tower?.name;
  }
  public get panelIcon(): string {
    return 'mdi mdi-file-image-outline';
  }
  public get isWidgetStageInput(): boolean {
    return this.chartWidgetData?.equipmentType === TowerEquipmentTypeEnum.None;
  }
  public get modalTitle(): string | null{
    if (this.isWidgetStageInput && this.chartWidgetData!.displayValue) {
      return `${this.$t('tower.stages.inputs')}
      - ${this.$t(`tower.displayValues.${toCamelCase(this.chartWidgetData?.displayValue)}`)}`;
    } else if (this.chartWidgetData?.equipmentType && this.chartWidgetData?.displayValue) {
      return `${this.$t(`tower.equipments.types.${toCamelCase(this.chartWidgetData.equipmentType)}`)}
           - ${this.$t(`tower.displayValues.${toCamelCase(this.chartWidgetData.displayValue)}`)}`;
    } else {
      return null;
    }
  }
    public async mounted() {
        await store.dispatch(
            `${VuexModuleNamespaces.error}/${ErrorStore.actions.tryExecute.name}`,
            {
                action: async () =>
                store.dispatch(
                    `${VuexModuleNamespaces.tower}/${TowerStore.actions.loadTower.name}`,
                    this.assetKey
                ),
              errorMsg: 'Error loading scenarios.',
            }
          );
        store.commit(`${VuexModuleNamespaces.tower}/${TowerStore.mutations.setStagedInputsFlag.name}`, true);
        this.loadingAlertHistoryData(null);
    }
  private async loadingAlertHistoryData($state: any): Promise<void> {
    if (this.assetKey !== null) {
      // Load complete alert history based off tower key
      const request: ITowerAlertHistoryRequest = {
        key: this.assetKey,
        currentPage: this.currentPage,
        sortBy: this.sortByKey,
        sortDesc: this.sortDesc,
        activeOnly: this.activeOnly
      };
      this.isLoadingAlerts = true;
      await store.dispatch(`${VuexModuleNamespaces.tower}/${TowerStore.actions.loadAlertHistory.name}`,request);
      this.alertHistory = [...this.alertHistory, ...this.currentAlertHistory];
      this.currentPage++;
      this.isLoadingAlerts = false;
      if ($state) {
        $state.loaded();
        if (this.currentAlertHistory.length === 0) {
          $state.complete();
        }
      }
    }
  }
  public toggleDropdown() {
    const sortDropdown = this.$refs.sortDropdown as BDropdown;
    if (sortDropdown.visible === false) {
      sortDropdown.show();
    } else {
      sortDropdown.hide();
    }
  }
  public async loadAlertData(alert: any) {
    this.chartWidgetData = await store.dispatch(`${VuexModuleNamespaces.tower}/${TowerStore.actions.loadHistoricalAlertData.name}`,alert);
    setTimeout(() => { this.loadingData = false; }, 500);
  }
  public showModal(alert: any) {
    this.isModalOpen = !this.isModalOpen;
    this.loadingData = true;
    this.loadAlertData(alert);
  }
  public hideModal(value: any): void {
    this.isModalOpen = value;
    setTimeout(() => { this.chartWidgetData = {} as ITowerIndicator; }, 500);
  }
  public sortBy(action: string) {
    switch (action) {
      case 'newest':
        this.sortDesc = true;
        this.activeOnly = false;
        break;
      case 'oldest':
        this.sortDesc = false;
        this.activeOnly = false;
        break;
      case 'active':
        this.sortDesc = true;
        this.activeOnly = true;
        break;
      default:
    }
    this.currentPage = 1;
    this.alertHistory = [];
    this.loadingAlertHistoryData(null);
  }
  public togglePanel(): void {
    this.showTowerGraphic = true;
    this.isPanelOpen = !this.isPanelOpen;
  }
  // Watchers
  // Emitters
}
