import BaseService from '@/services/common/base-service';
import { SmartAirOverview } from '@/view-models/smart-air/smart-air-data';
import { AxiosInstance } from 'axios';

export class AssetSmartAirService extends BaseService {

  constructor(axiosInstance: AxiosInstance, smartAirUrl: string) {
    super(`${smartAirUrl}/api`, axiosInstance);
  }

  public async getEmberOverviewData(assetKey: string): Promise<SmartAirOverview[]> {
    return this.httpClient.get<any>(`SmartAir/heater/${assetKey}/overview`).catch(() => {});
  }
}
