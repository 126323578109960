import Vue from 'vue';
import Vuex from 'vuex';
import { AppStore, IAppStoreState } from '@/store/app/appStore';
import { AssetStore, IAssetStoreState } from '@/store/assetStore/assetStore';
import { BurnerStore, IBurnerStoreState } from '@/store/burner/burnerStore';
import { ErrorStore, IErrorStoreState } from '@/store/error/errorStore';
import { UserStore, IUserStoreState } from '@/store/user/userStore';
import { AssetScenarioStore, IAssetScenarioStoreState } from '@/store/assetScenario/assetScenarioStore';
import { AssetSmartAirStore, IAssetSmartAirStoreState } from './smartAir/smartAirStore';
import { TowerStore, ITowerStoreState } from '@/store/tower/towerStore';

Vue.use(Vuex);

export interface IRootState {
  app: IAppStoreState;
  asset: IAssetStoreState;
  burner: IBurnerStoreState;
  error: IErrorStoreState;
  user: IUserStoreState;
  assetScenario: IAssetScenarioStoreState;
  smartAir: IAssetSmartAirStoreState;
  tower: ITowerStoreState;
}

export default new Vuex.Store({
  modules: {
    app: AppStore,
    asset: AssetStore,
    burner: BurnerStore,
    error: ErrorStore,
    user: UserStore,
    assetScenario: AssetScenarioStore,
    smartAir: AssetSmartAirStore,
    tower: TowerStore
  }
});

// update these module namespaces as registered module names are update above
export const VuexModuleNamespaces = {
  app: 'app',
  asset: 'asset',
  burner: 'burner',
  error: 'error',
  user: 'user',
  assetScenario: 'assetScenario',
  smartAir: 'smartAir',
  tower:'tower'
};
