import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IAsset } from '@/view-models/asset/assets-view-models';

export class AssetService extends BaseService {

  constructor(axiosInstance: AxiosInstance, baseApiUrl: string) {
    super(`${baseApiUrl}/users/current`, axiosInstance);
  }

  public async getCustomerAssets(): Promise<IAsset[]> {
    return this.httpClient.get<IAsset[]>('assignmentTree');
  }
}
