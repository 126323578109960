


































import { Vue, Prop, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import Icon from '@/components/common/Icon.vue';
import { IStatusBurner } from '@/view-models/burner/burner-view-models';
import { FuelLineSubTypes } from '@/enums/fuelline-data';

Vue.use(BootstrapVue);

@Component({
  name: 'notification',
  components: {
    Icon,
  },
})
export default class HomeNotifications extends Vue {
  @Prop({ required: true })
  public burner!: IStatusBurner;

  get toDisplay(): boolean {
    return this.isBurnerOff || this.burner.operationType === 'Maintenance';
  }

  get isBurnerOff(): boolean {
    return this.burner.fuelLines!.length > 0 ?  !this.burner.fuelLines!.some((fuelLine) => fuelLine.subType !== FuelLineSubTypes.ThreeWayValve && fuelLine.isOn) : false;
  }
}
