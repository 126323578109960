import { GetterTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';
import  find  from 'lodash/find';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { IDiagnosticScenario } from '@/view-models/asset/asset-scenario-view-model';
import { AssetScenarioService } from '@/services/asset/asset-scenario-service';
import { DiagnosticColorState } from '@/enums/diagnostic-state';

export interface IAssetScenarioStoreState {
  scenarios: IDiagnosticScenario[];
  assetColor: string;
}

export interface IAssetScenarioStoreGetters extends GetterTree<IAssetScenarioStoreState, IRootState> {
  getScenarios(state: IAssetScenarioStoreState): IDiagnosticScenario[];
  getAssetColor(state: IAssetScenarioStoreState): string;
}

export interface IAssetScenarioStoreActions extends ActionTree<IAssetScenarioStoreState, IRootState> {
  loadScenarios(context: IAssetScenarioContext, assetKey: string): void;
}

export type IAssetScenarioContext = ActionContext<IAssetScenarioStoreState, IRootState>;

export const AssetScenarioStore = {
    namespaced: true as true,
    state: {
      scenarios: [],
      assetColor: '',
    } as IAssetScenarioStoreState,
    getters:  {
      getScenarios: (state: IAssetScenarioStoreState) => state.scenarios,
      getAssetColor: (state: IAssetScenarioStoreState) => state.assetColor
    } as IAssetScenarioStoreGetters,
    actions: {
      async loadScenarios(context: IAssetScenarioContext, assetKey: string): Promise<void> {
        const conf = await ConfigFactory.GetConfig();
        const assetScenarioService = new AssetScenarioService(sharedAxiosInstance,
        process.env.VUE_APP_INTELLIGENT_DIAGNOSTICS_API_URL ?
        process.env.VUE_APP_INTELLIGENT_DIAGNOSTICS_API_URL :
        conf.get('intelligentDiagnosticsUrl'));
        const response = await assetScenarioService.getScenarios(assetKey);
        let color: string = DiagnosticColorState.Gray;
        if (response.length) {
          if (find(response, [ DiagnosticColorState.DiagnosticState, DiagnosticColorState.PhysicsAndAI ])) {
            color = DiagnosticColorState.Orange;
          } else if (find(response, [ DiagnosticColorState.DiagnosticState, DiagnosticColorState.Physics ])) {
            color = DiagnosticColorState.Yellow;
          } else {
            color = DiagnosticColorState.Green;
          }
        }
        context.state.assetColor = color;
        context.state.scenarios = response;
      }
    } as IAssetScenarioStoreActions
};
