




























// Libraries
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
// View Models
// Components
import Loading from '@/components/common/Loading.vue';
import HelperMethods from '@/utils/helper-methods';
// Services
// Stores
@Component({
  name: 'tower-modal',
  components: {
    Loading
  }
})
export default class TowerModal extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public value!: boolean;
  @Prop({ required: true })
  public title!: string;
  @Prop({ required: false, default: null })
  public subtitle!: string;
  @Prop({ required: false, default: 'xl' })
  public size!: string;
  @Prop({ required: false, default: true })
  public centered!: boolean;
  @Prop({ required: false, default: true })
  public hideFooter!: boolean;
  @Prop({ required: false, default: null })
  public loadingData!: boolean
  // VUEX
  // Properties
  public cid: string = HelperMethods.newGuid();
  // Fields
  // Getters
  public get showModal(): boolean {
    return this.value;
  }
  public set showModal(value: boolean) {
    this.toggleModal(value);
  }
  // Emitters
  @Emit('hide-modal')
  public toggleModal(value: boolean) {
    return value;
  }
}
