


























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store';
import { AssetSmartAirStore } from '@/store/smartAir/smartAirStore';
import { SmartAirMethodName } from '@/enums/smart-air-data';
import VueMarkdown from 'vue-markdown';
import { pahEventBus } from '@/services/eventBus/portal-asset-home-event-bus';

@Component({
  name: 'smart-air-overview',
  components: {
    VueMarkdown,
  },
})
export default class SmartAirOverview extends Vue {
  @Prop({ required: true })
  public internaluser!: boolean;
  public smartAirMethodName = SmartAirMethodName;
  public showWarning = false;
  public hoveredIndex: number = 0;

  get smartAirData(): any[] {
    return store.getters[
      `${VuexModuleNamespaces.smartAir}/${AssetSmartAirStore.getters.getSmartAirData.name}`
    ];
  }

  get smartAirApiError(): boolean {
    return store.getters[
      `${VuexModuleNamespaces.smartAir}/${AssetSmartAirStore.getters.smartAirApiError.name}`
    ];
  }

  public launchSmartAir(zoneIdentifier: string) {
    if (this.internaluser) {
    pahEventBus.$emit('zoneKey', zoneIdentifier);
    }
  }

  public showWarningTooltip(index: number): void {
    this.showWarning = true;
    this.hoveredIndex = index;
  }

  public hideWarningTooltip(index: number): void {
    this.showWarning = false;
    this.hoveredIndex = index;
  }
}
