import { GetterTree, ActionTree, ActionContext } from 'vuex';
import { IRootState } from '..';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import { AssetSmartAirService } from '@/services/asset/asset-smart-air-service';
import { SmartAirOverview } from '@/view-models/smart-air/smart-air-data';

export interface IAssetSmartAirStoreState {
  smartAirData: SmartAirOverview[];
  smartAirOverviewLoading: boolean;
  isApiError: boolean;
}

export interface IAssetSmartAirStoreGetters extends GetterTree<IAssetSmartAirStoreState, IRootState> {
  getSmartAirData(state: IAssetSmartAirStoreState): SmartAirOverview[];
  getSmartAirLoader(state: IAssetSmartAirStoreState): boolean;
  smartAirApiError(state: IAssetSmartAirStoreState): boolean;
}

export interface IAssetSmartAirStoreActions extends ActionTree<IAssetSmartAirStoreState, IRootState> {
  loadSmartAirOverviewData(context: IAssetSmartAirContext, assetKey: string): void;
}

export type IAssetSmartAirContext = ActionContext<IAssetSmartAirStoreState, IRootState>;

export const AssetSmartAirStore = {
    namespaced: true as true,
    state: {
      smartAirData: [],
      smartAirOverviewLoading: false,
      isApiError: false
    } as IAssetSmartAirStoreState,
    getters:  {
      getSmartAirData: (state: IAssetSmartAirStoreState) => state.smartAirData,
      getSmartAirLoader:  (state: IAssetSmartAirStoreState) => state.smartAirOverviewLoading,
      smartAirApiError: (state: IAssetSmartAirStoreState) => state.isApiError
    } as IAssetSmartAirStoreGetters,
    actions: {
      async loadSmartAirOverviewData(context: IAssetSmartAirContext, assetKey: string): Promise<void> {
        const conf = await ConfigFactory.GetConfig();
        context.state.isApiError = false;
        const assetSmartAirService = new AssetSmartAirService(sharedAxiosInstance,
        conf.get('smartAirApiUrl'));
        context.state.smartAirOverviewLoading = true;
        const response = await assetSmartAirService.getEmberOverviewData(assetKey);
        if (!response) context.state.isApiError = true;
        context.state.smartAirData = response;
        context.state.smartAirOverviewLoading = false;
      }
    } as IAssetSmartAirStoreActions
};
