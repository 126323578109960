import { getAuthToken } from '@/services/auth/auth';
import { UserStore } from '@/store/user/userStore';
import { AppStore } from '@/store/app/appStore';
import { AssetStore } from '@/store/assetStore/assetStore';

const authInterceptor = async (config: any) => {
  const authorization = 'Authorization';
  const correlationId = 'OnPoint-Correlation-Id';
  const medaData = 'OnPoint-MetaData';
  const timeStamp = AppStore.state && AppStore.state.actionTime ? AppStore.state.actionTime : '';
  let metadata = {};
  if (UserStore.state && UserStore.state.customerAssetInfo) {
    metadata = UserStore.state.customerAssetInfo;
  } else {
    metadata = {
      customerId: UserStore.state && UserStore.state.currentUser ? UserStore.state.currentUser.activeCustomerKey : '',
      customerName: '',
      assetId: AssetStore.state && AssetStore.state.receivedAssetKey ?  AssetStore.state.receivedAssetKey : '',
      assetName: ''
    };
  }
  const token = await getAuthToken();
  const tokenData: any = JSON.parse(atob(token.split('.')[1]));
  const userId = tokenData['https://auth.onpointsolutions.com/claims/userKey'];
  // Do not add auth token when uploading direct to S3
  if (config.url.indexOf('s3.amazonaws.com') > -1) {
    return config;
  } else {
    config.headers[authorization] = `Bearer ${token}`;
    config.headers[correlationId] = `AH|${userId}|${timeStamp}`;
    config.headers[medaData] = btoa(JSON.stringify(metadata));
    return config;
  }
};

export default authInterceptor;
