import { ILocationViewModel } from '@/view-models/common/location-view-model';
import moment from 'moment';
import {
  ChartTypesEnum,
  DashboardTimeRangesEnum,
  EquipmentIndicatorDisplayValuesEnum,
  InputDateRangeEnum,
  ReportAxis,
  SeriesTypes,
  StageInputIndicatorDisplayValuesEnum,
  TowerEquipmentNameEnum,
  TowerEquipmentTypeEnum,
  TowerIndicatorDisplayValuesEnum,
  TowerInputTypesEnum,
  TowerThresholdRangesEnum,
  TowerUnitOfMeasurementsEnum,
  TowerValidationsDisplayValuesEnum,
  WidgetTypes
} from '@/enums/tower-home';

// Interface for TowerView Towers
export interface ITower {
  alerts: number;
  createdAt?: string;
  customerKey: string;
  assetGroupingKey?: string;
  customerSiteKey: string;
  imageSignedUrl?: string;
  key: string;
  lastModifiedAt?: string;
  lastTowerViewCalculation?: string;
  location?: ILocationViewModel;
  name: string;
  orgKey?: string;
  sortStagesAscending: boolean;
  stages: Array<ITowerStage>;
  validations?: Array<ITowerValidation>;
  indicators?: Array<ITowerIndicator>
}
// Interface for TowerView Stages
export interface ITowerStage {
  alerts?: Array<ITowerAlert>;
  equipment: Array<ITowerStageEquipment>;
  input: ITowerStageInput;
  stageKey: string;
  order: number;
}
export type UnixEpochTime = number; // milliseconds
export type WidgetDataPoint = [UnixEpochTime, number];
export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
  fromDate?: string;
  toDate?: string;
}

// Interface for TowerView Stage Equipment
export interface ITowerStageEquipment {
  inWarningLevel?: boolean;
  indicators: Array<IEquipmentIndicatorRequest | IEquipmentIndicator>;
  equipmentKey: string;
  name?: TowerEquipmentNameEnum;
  type: TowerEquipmentTypeEnum;
}

export interface IDisplayWidgetInput {
  key: string;
  displayValue: string;
  inputName: string;
  displayName?: string;
  sortOrder: number;
  unitOfMeasurement: string;
  chartType: SeriesTypes;
  axis: ReportAxis;
}
export type DateTimeInput = string | Date | moment.Moment | number;

export function asServerDateTime(val: DateTimeInput) {
  return moment(val).toISOString();
}
export type WidgetValueFormatter = (input: IDisplayWidgetInput, value: number) => string;
export interface IWidgetValueFormatters {
  yValue?: WidgetValueFormatter;
  yValueTooltip?: WidgetValueFormatter;
}

export interface ITowerStageInput {
  inWarningLevel?: boolean;
  indicators: Array<IStageInputIndicatorRequest | IStageInputIndicator>;
}
// Interface for TowerView Alerts
export interface ITowerAlert {
  displayValue: string;
  equipmentName: TowerEquipmentNameEnum;
  equipmentType: TowerEquipmentTypeEnum;
  formattedDisplayValue?: string;
  value: number;
}
// Interface for TowerView Historical Alert data
export interface ITowerHistoricalAlert {
  towerKey : string;
  stageKey: string;
  equipmentKey: string;
  equipmentName: string;
  equipmentType: TowerEquipmentTypeEnum;
  displayName: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  displayValue: EquipmentIndicatorDisplayValuesEnum | StageInputIndicatorDisplayValuesEnum;
  alertTimestamp: string;
  value: number;
  thresholdValue: number;
  status: string;
  clearedTimestamp: string;
  createdAt: string;
  chartSettings: IChartSettings;
  uom: TowerUnitOfMeasurementsEnum;
  data?: Array<number[]>;
  type: TowerInputTypesEnum;
}
// Interface for a single Tower Threshold
export interface ITowerThreshold {
  alertType: TowerThresholdRangesEnum;
  color: string;
  maxValue: number;
  minValue: number;
  thresholdName: string;
  thresholdType: string;
}
// Interface for Widget Chart Settings
export interface IChartSettings {
  axis: string;
  chartType: ChartTypesEnum;
  isFullScreen: boolean;
  showTitleAtBottom?: boolean;
  sortOrder: number;
  widgetType: WidgetTypes;
  scale: { min: number | null, max: number | null };
}
// Interface for property updates via updateStageWidgetData & updateValidationsWidgetData methods
export interface IObjectPropertyUpdate {
  validationKey?: string;
  indicatorKey?: string;
  equipmentKey?: string;
  equipmentType: string;
  path: string;
  value: any;
}
// Interface for tower view local storage
export interface ITowerStageStorageModel {
  value: string;
  timestamp: number;
  date?: any;
}
// Interface for Tower Validations
export interface ITowerValidation {
  chartSettings: IChartSettings;
  displayValue: string;
  equipmentType: TowerEquipmentTypeEnum;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: TowerValidationsDisplayValuesEnum
  }>;
  validationKey: string;
  type: TowerInputTypesEnum;
  uom: TowerUnitOfMeasurementsEnum;
}
// Interface for TowerView Equipment Indicators
export interface IEquipmentIndicator extends IEquipmentIndicatorResponse{
  chartSettings: IChartSettings;
  equipmentKey: string;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  uom: TowerUnitOfMeasurementsEnum;
  toShow: string;
  data: Array<WidgetDataPoint>;
}
// Interface ofr TowerView Stage Input Indicators
export interface IStageInputIndicator extends IStageInputIndicatorResponse{
  chartSettings: IChartSettings;
  key: string;
  inWarningLevel: boolean;
  thresholds: Array<ITowerThreshold>;
  uom: TowerUnitOfMeasurementsEnum;
  toShow: string;
}
// Interface for TowerView Tower Level Indicators
export interface ITowerIndicator {
  chartSettings: IChartSettings;
  inputs: Array<{
    data: Array<WidgetDataPoint>;
    dataSetName: string;
  }>;
  displayValue: TowerIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentName?: TowerEquipmentNameEnum;
  key: string;
  type: TowerInputTypesEnum;
  uom: TowerUnitOfMeasurementsEnum;
}
// Interface for Combined Chart Widget Data Types When Rendering Generic Chart Widget Container
// export type IWidgetDataTypes = ITowerIndicator & ITowerValidation & IEquipmentIndicator;
export type IWidgetDataTypes = any; // TODO refactor to incorporate the various types from the line above

// REQUESTS & RESPONSES
export interface ITowerWidgetDataRequest {
  towerKey: string;
  range: IDateRange;
  sampleSize: number;
}
export interface ITowerWidgetDataResponse extends Omit<ITowerWidgetDataRequest, 'range'|'sampleSize'> {
  inputs: Array<ITowerIndicator>
}

export interface IStageWidgetDataRequest extends ITowerWidgetDataRequest {
  stageKey: string;
  inputs: Array<IEquipmentIndicatorRequest|IStageInputIndicatorRequest>;
}
export interface IStageWidgetDataResponse extends Omit<IStageWidgetDataRequest, 'range'|'sampleSize'>{
  inputs: Array<IEquipmentIndicatorResponse|IStageInputIndicatorResponse>;
}

export interface IEquipmentIndicatorRequest {
  displayValue: EquipmentIndicatorDisplayValuesEnum;
  equipmentName?: TowerEquipmentNameEnum;
  equipmentType: TowerEquipmentTypeEnum;
  equipmentKey: string;
  key?: string;
  type: TowerInputTypesEnum;
  data?: Array<WidgetDataPoint>;
}
export interface IEquipmentIndicatorResponse extends IEquipmentIndicatorRequest {
  data: Array<WidgetDataPoint>;
}

export interface IStageInputIndicatorRequest {
  displayValue: StageInputIndicatorDisplayValuesEnum;
  equipmentType: TowerEquipmentTypeEnum;
  type: TowerInputTypesEnum;
  key?: string;
}
export interface IStageInputIndicatorResponse extends IStageInputIndicatorRequest {
  data: Array<WidgetDataPoint>;
}

export interface IValidationsWidgetDataRequest {
  towerKey: string;
  range: IDateRange;
  sampleSize: number;
  inputs: Array<Pick<ITowerValidation, 'validationKey'>>;
}
export interface IValidationsWidgetDataResponse extends Pick<IValidationsWidgetDataRequest, 'towerKey'> {
  inputs: Array<ITowerValidation>
}

export interface ITowerCustomThresholdRequest extends ITowerThreshold {
  towerKey: string;
  stageKey: string;
  equipmentKey?: string;
  equipmentName: string;
  equipmentType: string;
  displayName: string;
}
export interface ITowerAlertHistoryRequest {
  key: string;
  currentPage: number;
  sortBy: string;
  sortDesc: boolean;
  activeOnly: boolean;
}
export interface ITowerThresholdsResponse {
  towerKey: string;
  stages: Array<{
    stageInputs: Array<{
      displayName: StageInputIndicatorDisplayValuesEnum;
      thresholds: Array<ITowerThreshold>
    }>;
    equipment: Array<{
      equipmentKey: string;
      equipmentName: TowerEquipmentTypeEnum;
      performanceIndicators: Array<{
        displayName: EquipmentIndicatorDisplayValuesEnum;
        thresholds: Array<ITowerThreshold>
      }>
    }>;
    stageKey: string;
  }>
}

// CLASSES
export class TowerIndicatorRequest {
  public displayValue: TowerIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum;
  public key: string;
  public type: TowerInputTypesEnum;

  constructor(displayValue: TowerIndicatorDisplayValuesEnum, key: string) {
    this.displayValue = displayValue;
    this.equipmentType = TowerEquipmentTypeEnum.None;
    this.key = key;
    this.type = TowerInputTypesEnum.Tower;
  }
}
export class TowerIndicator implements ITowerIndicator {
  public chartSettings: IChartSettings = {} as IChartSettings;
  public inputs: Array<{
    data: Array<WidgetDataPoint>,
    dataSetName: TowerIndicatorDisplayValuesEnum
  }> = [];
  public displayValue: TowerIndicatorDisplayValuesEnum = {} as TowerIndicatorDisplayValuesEnum;
  public equipmentType: TowerEquipmentTypeEnum = {} as TowerEquipmentTypeEnum;
  public key: string;
  public type: TowerInputTypesEnum = {} as TowerInputTypesEnum;
  public uom: TowerUnitOfMeasurementsEnum = {} as TowerUnitOfMeasurementsEnum;
  constructor(input: any, towerKey: string) {
    this.key = `${towerKey}:${input.displayValue}`;
    Object.assign(this, input);
  }
}

export class TowerThreshold implements ITowerThreshold {
  public alertType: TowerThresholdRangesEnum;
  public color: string;
  public maxValue: number;
  public minValue: number;
  public thresholdName: string;
  public thresholdType: string;

  constructor(alertType: TowerThresholdRangesEnum, color: string, maxValue: number, minValue: number, thresholdName: string, thresholdType: string) {
    this.alertType = alertType;
    this.color = color;
    this.maxValue = maxValue;
    this.minValue = minValue;
    this.thresholdName = thresholdName;
    this.thresholdType = thresholdType;
  }
}

export interface IDateRangeObjects {
	fromDate: Date;
	toDate: Date;
	dateType?: InputDateRangeEnum;
}

export interface IDashboardTimeRangeSetting {
  type: DashboardTimeRangesEnum;
  range?: IDateRangeObjects;
  changeByUser?: boolean;
}

export class DateRange implements IDateRange {
	public fromDate: string = '';
	public toDate: string = '';

	constructor(dateRange?: IDateRange) {
		if (dateRange != null) {
			Object.assign(this, dateRange);
		}
	}

	public static prepareRangeForServer(range: IDateRange): IDateRange {
		const from: Date = new Date(range.fromDate!);
		const to: Date = new Date(range.toDate!);
		return new DateRange({
      fromDate: asServerDateTime(from),
      toDate: asServerDateTime(to)
		});
	}
}

export function dateObjectsToDateRange(range: IDateRangeObjects): IDateRange {
  return new DateRange({
    fromDate: asServerDateTime(range.fromDate),
    toDate: asServerDateTime(range.toDate)
  });
}
