import BaseService from '@/services/common/base-service';
import { AxiosInstance } from 'axios';
import { IStatusBurner } from '@/view-models/burner/burner-view-models';

export class AssetBurnerService extends BaseService {

  constructor(axiosInstance: AxiosInstance, emaApiUrl: string) {
    super(`${emaApiUrl}/api`, axiosInstance);
  }

  public async getBurnersByAssetKey(key: string): Promise<any[]> {
    const url = `heater/${key}/burners`;
    return this.httpClient.get<IStatusBurner[]>(url);
  }
}
