import moment from 'moment';

export function asDate(date: any, asIs: boolean = false): Date | null {
    if (date == null) {
        return null;
    } else if (date instanceof Date) {
        return isNaN(date.valueOf()) ? null : date as Date;
    } else if (typeof date === 'number') {
        return new Date(date);
    } else if (moment.isMoment(date)) {
        return (date as moment.Moment).toDate();
    } else if (typeof date === 'string') {
        if (!asIs) {
        date = date.replace('T', ' ');
        }
        return new Date(Date.parse(date));
    } else if (typeof date === 'boolean') {
        if (!date) {
        return null;
        }
    } else {
        throw new TypeError('Bad date type: ' + date);
    }
    return null;
}

export function asNumericDateString(date: any): string {
    if (date instanceof Date) {
        return moment(date).format('MM/DD/YY h:mm a');
    } else {
        throw new TypeError('Bad date type: ' + date);
    }
}