























import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import i18n from '@/i18n';
import { elasticRum } from '@/plugins/elastic-rum';
import BaseComponent from '@/components/common/BaseComponent';
import { TransactionName } from '@/enums/transaction-name';
import AssetHome from './components/assetHome/AssetHome.vue';
import Icon from './components/common/Icon.vue';
import Sprite from './components/common/Sprite.vue';
import Error from './components/modals/Error.vue';
import { authGuard } from './services/auth/auth';
import {
  pahEventBus,
  pahEvents,
} from './services/eventBus/portal-asset-home-event-bus';
import store, { VuexModuleNamespaces } from '@/store/';
import { AppStore } from '@/store/app/appStore';
import Loading from './components/common/Loading.vue';
import moment from 'moment';
import { format, register, LocaleFunc } from 'timeago.js';
import MainAlert from './components/assetHome/components/MainAlert.vue';
import { UserStore } from '@/store/user/userStore';
import TowerHome from '@/components/towerHome/TowerHome.vue';

const locale = ((inputNumber: number, index: number) => {
  // number: the time ago / time in number;
  // index: the index of array below;
  // totalSec: total seconds between date to be formatted and today's date;
  return [
    ['just now', 'right now'],
    ['just now', 'right now'],
    ['1 minute ago', 'in 1 minute'],
    ['%s minutes ago', 'in %s minutes'],
    ['1 hour ago', 'in 1 hour'],
    ['%s hours ago', 'in %s hours'],
    ['1 day ago', 'in 1 day'],
    ['%s days ago', 'in %s days'],
    ['1 week ago', 'in 1 week'],
    ['%s weeks ago', 'in %s weeks'],
    ['1 month ago', 'in 1 month'],
    ['%s months ago', 'in %s months'],
    ['1 year ago', 'in 1 year'],
    ['%s years ago', 'in %s years'],
  ][index];
}) as LocaleFunc;
register('en_US', locale);

(async function initializeApp() {
  elasticRum();
})();

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.filter('formatDateLongWithoutTime', (value: string) => {
  return value ? moment(Date.parse(value)).format('MMMM D, YYYY') : '-';
});

Vue.filter('formatDateLong', (value: number) => {
  return value ? moment(String(value)).format('MMMM D, YYYY [at] h:mm a') : '-';
});

Vue.filter('formatDateShort', (value: number) => {
  return value ? moment(String(value)).format('MM/DD/YY h:mm a') : '-';
});

Vue.filter('formatDateWithTimeAgo', (value: number) => {
  if (!value) {
    return undefined;
  } else {
    const date = moment(String(value));
    if (moment().diff(date, 'days') <= 7) {
      return format(value, 'en_US');
    } else {
      return date.format('MM/DD/YY h:mm a');
    }
  }
});

Vue.filter('formatIntegerForDisplay', (value: number) => {
  if (value === -999999 || value === undefined || value === null) {
    return '-';
  } else {
    return value;
  }
});

Vue.filter('formatNumberForDisplay', (value: number) => {
  if (value === -999999 || value === undefined || value === null) {
    return '-';
  } else {
    return value.toFixed(2);
  }
});

Vue.filter('formatFirstLetterCapitalized', (value: string) => {
  if (value) {
    return value.charAt(0).toUpperCase() + value.substring(1).toLowerCase();
  } else {
    return value;
  }
});

const routes = [{ path: '*', component: AssetHome, name: 'AssetHome' }];

export const portalAssetHomeRouter = new VueRouter({
  mode: 'history',
  routes,
});

portalAssetHomeRouter.beforeEach(authGuard);

Vue.component('Icon', Icon);

@Component({
  name: 'app',
  components: {
    Error,
    Loading,
    AssetHome,
    Sprite,
    MainAlert,
    TowerHome
  },
  router: portalAssetHomeRouter,
  i18n
})
export default class PortalAssetHome extends BaseComponent {
  // VUE.JS Props
  @Prop({ required: true })
  public assetkey!: string;

  @Prop({ required: true, default: false })
  public internaluser!: boolean;

  @Prop({ required: true, default: '' })
  public timestamp!: string;

  @Prop({ required: true })
  public customerAssetDetails!: string;

  @Prop({ required: true })
  public entityType!: string;

  private created() {
    this.startTransaction(TransactionName.AssetHomePageLoad);
    pahEventBus.$on(pahEvents.scenarioKey, async (key: string) => {
      this.$emit(pahEvents.scenarioKey, key);
    });
    // Clear leftover listeners, if present.
    // Need to listen to selectedKey event to be caught in portal
    // this.clearPahEventBus();
    // Listen for route changes
    this.$router.afterEach(() => {
      store.commit(
        `${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`,
        false
      );
    });
  }

  private mounted() {
    // Handle route changes from parent
    this.setRouteChangeHandler(true);
    this.onCustomerOrAssetChange();
  }

  private beforeDestroy() {
    this.setRouteChangeHandler(false);
  }
  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    this.setRouteChangeHandler(false);
  }

  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.match(/^\/?assets\/heater\//)) {
      store.commit(
        `${VuexModuleNamespaces.app}/${AppStore.mutations.updateIsLoading.name}`,
        false
      );
    }
  }

  private clearPahEventBus() {
    pahEventBus.$off();
  }

  private setRouteChangeHandler(on: boolean) {
    if (
      (window as any).eftEventBus !== null &&
      (window as any).eftEventBus !== undefined
    ) {
      const method = on ? '$on' : '$off';
      (window as any).eftEventBus[method](
        'onpoint::routeChange',
        this.handleRouteChange
      );
    }
  }

  get isLoading() {
    return store.getters[
      `${VuexModuleNamespaces.app}/${AppStore.getters.isLoading.name}`
    ];
  }

  //Watchers
  @Watch('timestamp', {​ immediate: true }​)
  public onEmberRun(): void {}

  @Watch('internaluser', {​ immediate: true }​)
  public onUserRun(): void {}

  @Watch('customerAssetDetails', {​ immediate: true, deep: true }​)
  public onCustomerOrAssetChange(): void {
    if (this.customerAssetDetails) {
      store.commit(`${VuexModuleNamespaces.user}/${UserStore.mutations.updateCustomerAssetInfo.name}`, JSON.parse(this.customerAssetDetails));
    }
  }

}
