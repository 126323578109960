




import { Vue, Component } from 'vue-property-decorator';
import store, { VuexModuleNamespaces } from '@/store';
import { AssetScenarioStore } from '@/store/assetScenario/assetScenarioStore';

@Component({
  name: 'main-alert',
})
export default class MainAlert extends Vue {
  get assetColor(): string {
    return store.getters[`${VuexModuleNamespaces.assetScenario}/${AssetScenarioStore.getters.getAssetColor.name}`];
  }
}
