import store, { IRootState } from '..';
import { GetterTree, MutationTree, ActionTree, ActionContext } from 'vuex';
import {
    UnixEpochTime,
    IObjectPropertyUpdate,
    IStageWidgetDataResponse,
    ITower, ITowerHistoricalAlert, ITowerCustomThresholdRequest,
    ITowerStage, ITowerThresholdsResponse, ITowerValidation,
    IValidationsWidgetDataResponse, ITowerIndicator, ITowerAlertHistoryRequest,
    IStageWidgetDataRequest, asServerDateTime, IDashboardTimeRangeSetting, ITowerWidgetDataRequest, dateObjectsToDateRange, ITowerWidgetDataResponse, TowerIndicator
} from '@/view-models/tower/tower-view-models';
import sharedAxiosInstance from '@/services/common/api-service';
import ConfigFactory from '@/services/config/config';
import TowerService from '@/services/asset/tower-service';
import { toCamelCase } from '@/utils/string';
import {
  ChartTypesEnum,
  ReportAxis,
  TowerEquipmentNameEnum,
  TowerEquipmentTypeEnum,
  TowerInputTypesEnum,
  WidgetTypes,
  TowerStageFilterOptionEnum,
  StageInputIndicatorDisplayValuesEnum,
  DashboardTimeRangesEnum
} from '@/enums/tower-home';
import i18n from '@/i18n';
import { createEquipmentIndicator, equipmentIndicatorsMap, getUomByDisplayValue } from '@/utils/tower-utils';

const initTowerService = async () => {
    const conf = ConfigFactory.GetConfig();
    return new TowerService(sharedAxiosInstance,
        (await conf).get('baseApiUrl'));
};
export interface ITowerStoreState {
  currentTower: ITower | null;
  currentStage: ITowerStage | null;
  currentTimeSelections: Array<UnixEpochTime>;
  currentDataSampleSize: number;
  currentAlertHistory: Array<ITowerHistoricalAlert>;
  towerStageFilter: TowerStageFilterOptionEnum;
  isStageInputsEnabled: boolean;
  currentTimeRange: IDashboardTimeRangeSetting;
}
export interface ITowerStoreGetters extends GetterTree<ITowerStoreState, IRootState> {
    getCurrentTower(state: ITowerStoreState): ITower;
    getIsStageInputsEnabled(state: ITowerStoreState): boolean;
    getCurrentAlertHistory(state: ITowerStoreState): Array<ITowerHistoricalAlert>;
    getCurrentTimeSelections(state: ITowerStoreState): Array<UnixEpochTime>;
}
export interface TowerMutations extends MutationTree<ITowerStoreState> {
    setTower(state: ITowerStoreState, tower: ITower): void;
    setAlertHistory(state: ITowerStoreState, response: Array<ITowerHistoricalAlert>): void;
    setStagedInputsFlag(state: ITowerStoreState, value: boolean): void;
    setStage(state: ITowerStoreState, stageKey: string): void;
    setTowerWidgetData(state: ITowerStoreState, response: ITowerWidgetDataResponse): void;
    setCurrentTimeSelections(state: ITowerStoreState, selections: Array<UnixEpochTime>): void;
}
export interface IAssetScenarioStoreActions extends ActionTree<ITowerStoreState, IRootState> {
    loadTower(context: ITowerContext, key: string): void;
    loadAlertHistory(context: ITowerContext, request: ITowerAlertHistoryRequest): void;
    loadHistoricalAlertData(context: ITowerContext, alert: any): void;
    loadTowerWidgetData(context: ITowerContext, key: string): void;
}
export type ITowerContext = ActionContext<ITowerStoreState, IRootState>;

export const TowerStore = {
    namespaced: true as true,
    state: {
        currentTower : null,
        currentStage : null,
        currentTimeSelections : [],
        currentDataSampleSize : 400,
        currentAlertHistory : [],
        towerStageFilter : TowerStageFilterOptionEnum.All,
        isStageInputsEnabled: false,
        currentTimeRange: {
            type: DashboardTimeRangesEnum.OneWeek,
            range: {
              fromDate: new Date(Math.abs(new Date().getTime()) - (7 * 86400000)), // Today - (7 * Total seconds in day) = 1 Week
              toDate: new Date() // Today
            }
        }
    } as ITowerStoreState,
    getters: {
        getCurrentTower: (state: ITowerStoreState) => {
            return state.currentTower;
        },
        getIsStageInputsEnabled: (state: ITowerStoreState) => {
            return state.isStageInputsEnabled;
        },
        getCurrentAlertHistory: (state: ITowerStoreState) => {
            return state.currentAlertHistory;
        },
        getCurrentTimeSelections: (state: ITowerStoreState) => {
            return state.currentTimeSelections;
        }
    } as ITowerStoreGetters,
    mutations: {
        setTower(state: ITowerStoreState, tower: ITower): void {
            tower.stages = (tower.stages ?? []).map((stage) => {
                stage.equipment = (stage?.equipment ?? []).map((equipment) => {
                  equipment.indicators = (equipmentIndicatorsMap[equipment.type] ?? [])
                    .map(displayValue => createEquipmentIndicator(equipment, displayValue));
                  return equipment;
                });
                if (state.isStageInputsEnabled) {
                  stage.input = {
                    indicators: Object.values(StageInputIndicatorDisplayValuesEnum).map((displayValue) => {
                      return {
                        displayValue,
                        equipmentType: TowerEquipmentTypeEnum.None,
                        type: TowerInputTypesEnum.TowerStageInput
                      };
                    })
                  };
                } else {
                  stage.input = {
                    indicators: []
                  };
                }
                return stage;
            });
            if (!state.isStageInputsEnabled) {
                tower.alerts = tower.stages.reduce((total, stage) => total + stage.alerts!.length, 0);
            }
            // Create formatted alert display text
            for (const stage of tower.stages) {
                stage.alerts = stage.alerts?.map((alert) => {
                const equipmentType = alert.equipmentType
                    ? i18n.t(`tower.equipments.types.${toCamelCase(alert.equipmentType)}`).toString()
                    : '';
                const displayValue = alert.displayValue
                    ? i18n.t(`tower.displayValues.${toCamelCase(alert.displayValue)}`).toString()
                    : '';
                const value = alert.value.toFixed(2).toString();
                if (alert.equipmentName === TowerEquipmentNameEnum.Inputs) {
                alert.formattedDisplayValue = i18n.t('tower.stages.input') + ': ' + displayValue + ' - ' + value;
                } else if (alert.equipmentName) {
                const equipmentName = (alert.equipmentType || alert.equipmentName)
                    ? i18n.t(`tower.equipments.types.${toCamelCase(alert.equipmentType + alert.equipmentName)}`).toString()
                    : '';
                    alert.formattedDisplayValue = equipmentName + ': ' + displayValue + ' - ' + value;
                } else {
                    alert.formattedDisplayValue = equipmentType + ': ' + displayValue + ' - ' + value;
                }
                return alert;
                });
            }
            // Save the modified tower object to the store
            state.currentTower = tower;
        },
        setAlertHistory(state: ITowerStoreState, response: Array<ITowerHistoricalAlert>) {
            if (response) {
                state.currentAlertHistory = response;
                for (const alert of state.currentAlertHistory) {
                alert.chartSettings = {
                    chartType: ChartTypesEnum.Line,
                    widgetType: WidgetTypes.Chart,
                    axis: ReportAxis.Left,
                    sortOrder: 0,
                    isFullScreen: true,
                    scale: { min: null, max: null },
                    showTitleAtBottom: false
                };
                const isStageInput = alert.equipmentType === TowerEquipmentTypeEnum.None;
                alert.data = [];
                alert.type = isStageInput ? TowerInputTypesEnum.TowerStageInput : TowerInputTypesEnum.TowerEquipment;
                alert.uom = getUomByDisplayValue(alert.displayName, alert.type);
                alert.displayValue = alert.displayName;
                }
            }
        },
        setStagedInputsFlag(state: ITowerStoreState, value: boolean) {
            state.isStageInputsEnabled = value;
        },
        setStage(state: ITowerStoreState, stageKey: string) {
            state.currentStage = state.currentTower?.stages.find(stage => stage.stageKey === stageKey) ?? null;
        },
        setTowerWidgetData(state: ITowerStoreState, response: ITowerWidgetDataResponse) {
            if (response) {
              if (response.towerKey === state.currentTower?.key) {
                // Format response to front end friendly interface
                const towerIndicators = response.inputs.map((input) => {
                    return new TowerIndicator(input, response.towerKey);
                });

                for (const indicator of towerIndicators) {
                  // Build each performance indicator and prepare data to be used by highcharts API
                  indicator.chartSettings = {
                    chartType: {} as ChartTypesEnum,
                    widgetType: WidgetTypes.Chart,
                    axis: ReportAxis.Left,
                    sortOrder: 0,
                    isFullScreen: false,
                    scale: { min: null, max: null },
                    showTitleAtBottom: false
                  };
                  indicator.chartSettings.chartType = (indicator.chartSettings.widgetType === WidgetTypes.Chart) ? ChartTypesEnum.Line : ChartTypesEnum.Bullet;
                  indicator.chartSettings.showTitleAtBottom = indicator.chartSettings.widgetType === WidgetTypes.Chart;
                  indicator.uom = getUomByDisplayValue(indicator.displayValue, TowerInputTypesEnum.Tower);
                }

                state.currentTower.indicators = towerIndicators;
              }
            }
        },
        setCurrentTimeSelections(state: ITowerStoreState, selections: Array<UnixEpochTime>) {
            state.currentTimeSelections = selections;
        }
    } as TowerMutations,
    actions: {
        async loadTower(context: ITowerContext, key: string): Promise<void> {
            try {
              const towerService = await initTowerService();
              const tower = await towerService.getTower(key);
              context.commit('setTower', tower);
            } catch (e) {
              throw e;
            }
        },
        async loadAlertHistory(context: ITowerContext, request: ITowerAlertHistoryRequest): Promise<void> {
            try {
                const towerService = await initTowerService();
                const response = await towerService.getAlertHistory(request);
                context.commit('setAlertHistory',response);
            } catch (e) {
                throw e;
            }
        },
        async loadHistoricalAlertData(context: ITowerContext, alert: any): Promise<void> {
            if (alert) {
                const date = new Date(alert.alertTimestamp);
                const fromDate = asServerDateTime(date.getTime() - 43200000);
                const toDate = asServerDateTime(date.getTime() + 43200000);
                const type = alert.equipmentType === TowerEquipmentTypeEnum.None
                    ? TowerInputTypesEnum.TowerStageInput
                    : TowerInputTypesEnum.TowerEquipment;
                const payload: IStageWidgetDataRequest = {
                towerKey: alert.towerKey,
                stageKey: alert.stageKey,
                range: {
                    fromDate,
                    toDate
                },
                sampleSize: 100,
                inputs: [
                    {
                    equipmentKey: alert.equipmentKey,
                    type,
                    equipmentType: alert.equipmentType,
                    displayValue: alert.displayName,
                    equipmentName: alert.equipmentName
                    }
                ]
                };
                const towerService = await initTowerService();
                const response = await towerService.getStageWidgetData(payload);
                if (response) {
                alert.data = response.inputs[0].data;
                return alert;
                }
            }
        },
        async loadTowerWidgetData(context: ITowerContext, key: string): Promise<void> {
            const towerKey = context.state.currentTower ? context.state.currentTower.key : key;
            // Create the payload object
            const payload: ITowerWidgetDataRequest = {
              towerKey,
              range: dateObjectsToDateRange(context.state.currentTimeRange.range!),
              sampleSize: context.state.currentDataSampleSize
            };
            // Send the payload to the tower service to retrieve tower widget data from API
            const towerService = await initTowerService();
            const response = await towerService.getTowerWidgetData(payload);

            // Pass a valid non-empty response to setStageWidgetData, then resolve the promise
            if ((response?.inputs?.length ?? 0) > 0) {
              context.commit('setTowerWidgetData',response);
            }
            return Promise.resolve();
        }
    }
};
