export interface ILog {
    message: string;
    applicationName: string;
    browserName: string;
    browserVersion: string;
    frontendFeature: string;
    logLevel: string;
    additionalProperty: { [key: string]: string };
    stackTrace: string;
}

export enum LogFeature {
    'AH-AssetSelectedAssignmentTree' = 'Selected asset name is ',
    'AH-BurnerFilterAssetSummary' = 'Sort the burner status with '
}
