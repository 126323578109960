














import { Vue, Prop, Component } from 'vue-property-decorator';
import { BootstrapVue } from 'bootstrap-vue';
import Notification from './components/Notification.vue';
import store, { VuexModuleNamespaces } from '@/store';
import { IStatusBurner } from '@/view-models/burner/burner-view-models';
import { BurnerStore } from '@/store/burner/burnerStore';
import { FuelLineSubTypes } from '@/enums/fuelline-data';
import BaseComponent from '@/components/common/BaseComponent';
import { TransactionName } from '@/enums/transaction-name';

Vue.use(BootstrapVue);

@Component({
  name: 'home-notifications',
  components: {
    Notification
  }
})
export default class HomeNotifications extends BaseComponent {
  @Prop({ required: true })
  private sortNewestFirst!: boolean;

  private isKnownBurner(key: string): boolean {
    const burner: IStatusBurner | undefined = this.burnersFromEMA.find((statusBurner) => statusBurner.key === key);
    if (burner !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  get burnersFromEMA(): IStatusBurner[] {
    return store.getters[`${VuexModuleNamespaces.burner}/${BurnerStore.getters.getEMABurnersList.name}`];
  }

  get sortedBurners() {
    const burners = this.burnersFromEMA;
    const newestFirst = burners?.sort(
      (a, b) => {
        if (b.lastReadingTime && a.lastReadingTime) {
          return Date.parse(b.lastReadingTime) - Date.parse(a.lastReadingTime);
        } else if (a.lastReadingTime) {
          return -1; // a before undefined
        } else if (b.lastReadingTime) {
          return 1; // b before undefined
        } else {
          return 0;
        }
      });
    this.stopTransaction(TransactionName.AssetHomePageLoad);
    return this.sortNewestFirst ? newestFirst : newestFirst.reverse();
  }

  get noNotifications() {
    let noNotifications = true;
    this.burnersFromEMA.forEach((burner) => {
      if (this.isKnownBurner(burner.key)&& (burner.operationType === 'Maintenance'
        || (burner.fuelLines!.length > 0 && !burner.fuelLines!.some((fuelLine) => fuelLine.subType !== FuelLineSubTypes.ThreeWayValve && fuelLine.isOn)))) {
        noNotifications = false;
      }
    });
    return noNotifications;
  }
}
